

.password-wrapper {
    /*display: flex;
    flex-direction: column;
    align-items: center;*/
    padding: 8px 0 0 0;
}

.password-actions {
    text-align: center;
}

.password-hint-label {
    /*color: black;*/
}
