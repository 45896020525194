@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'MandatoryPlaything';
  src:
    local('MandatoryPlaything'),
    url('./assets/fonts/Mandatory.Plaything.otf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat'),
    url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
}

a {
  cursor: pointer;
}
.background {
  background-image: url('./assets/background.png');
}
.main-bg {
  background-image: url('./assets/main-bg.jpg');
  background-size: cover;
}
.round-rec {
  background-image: linear-gradient(0deg, #151922 0%, #25272c 100%);
  box-shadow: 0 0 10px #e9a134;
}
.bigpad {
  background-image: linear-gradient(0deg, #111111 0%, #444444 50%, #111111 100%);
}
.hover-item:hover {
  border-color: #e9a134;
  box-shadow: 0 0 10px #e9a134;
}
.hover-item img {
  filter: grayscale(100);
}
.hover-item:hover img {
  filter: grayscale(0);
}
.accordion-title {
  cursor: pointer;
}
.accordion-title .svg-inline--fa {
  transform: scaleY(-1);
}
.accordion-title.open .svg-inline--fa {
  transform: scaleY(1);
}
.accordion-item.collapsed {
  display: none;
}
.fire-img {
  width: 50px;
  margin: -15px -10px;
}
.totalBox {
  display: inline-block;
  position: relative;
  z-index: 2;
  border: 2px solid #e9a134;
  border-radius: 5px;
  width: 72px;
  height: 87px;
  text-align: center;
  margin: 0 4px;
  background: linear-gradient(180deg, #121212, #444444, #121212);
  box-shadow:
    inset 0 0 10px rgba(0, 0, 0, 0.47),
    0 0 15px rgba(233, 161, 52, 0.4);
  position: relative;
}
.totalBox:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  right: 0;
  border: 1px solid #1f1f1f;
  box-shadow: 0 1px 0 #585858;
}

.typeOfV {
}
.bronze:hover > div {
  border-color: #dea695;
  box-shadow: 0 0 10px #dea695;
}
.silver:hover > div {
  border-color: #898989;
  box-shadow: 0 0 10px #898989;
}
.gold:hover > div {
  border-color: #db9831;
  box-shadow: 0 0 10px #db9831;
}
.platinum:hover > div {
  border-color: #c9c9c9;
  box-shadow: 0 0 10px #c9c9c9;
}
.header-btn {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

input:focus {
  outline: none;
}
.btn-bronze {
  border-color: #dea695;
  color: #dea695;
  box-shadow: 0 0 8px #dea695;
  transition: all 0.3s ease-in-out 0s;
}
.btn-bronze:hover {
  background-color: #dea695;
  color: #fff;
}
.btn-white {
  border-color: #fff;
  color: #fff;
  box-shadow: 0 0 8px #fff;
  transition: all 0.3s ease-in-out 0s;
}
.btn-white:hover {
  background-color: #fff;
  color: #111111;
}
.btn-silver {
  border-color: #989898;
  color: #989898;
  box-shadow: 0 0 8px #989898;
  transition: all 0.3s ease-in-out 0s;
}
.btn-silver:hover {
  background-color: #989898;
  color: #fff;
}
.btn-silver.shadow-lg {
  box-shadow: 0 0 12px #989898;
}
.btn-gold {
  border-color: #e9a134;
  color: #e9a134;
  box-shadow: 0 0 8px #e9a134;
  transition: all 0.3s ease-in-out 0s;
}
.btn-gold:hover {
  background-color: #e9a134;
  color: #fff;
}

.btn-green {
  border-color: #91db27;
  color: #91db27;
  box-shadow: 0 0 8px #91db27;
  transition: all 0.3s ease-in-out 0s;
}
.btn-green:hover {
  background-color: #91db2722;
  color: #fff;
}
.btn-golden {
  border-color: #e9a134;
  box-shadow: 0 0 8px #e9a134;
  transition: all 0.3s ease-in-out 0s;
}
.btn-golden:hover {
  background-color: #e9a134;
}
.btn-golden.shadow-lg {
  box-shadow: 0 0 12px #e9a134;
}
.btn-disabled {
  pointer-events: none;
  border-color: #7b7b7b;
  color: #7b7b7b;
  box-shadow: 0 0 8px #7b7b7b;
  transition: all 0.3s ease-in-out 0s;
}
.list-disc ::marker {
  display: none;
}
.list-disc li {
  list-style: none;
}
.list-disc li::before {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: top;
  background: currentColor;
  border-radius: 100px;
  margin: 12px 11px 0 -20px;
}
.list-decimal ::marker {
}
textarea {
  resize: none;
}

@media screen and (max-width: 767px) {
  .totalBox {
    text-align: center;
  }
  .totalBox {
    height: 55px;
  }
  .totalBox + p {
  }
}
@media screen and (max-width: 556px) {
  .totalBox {
  }
  .totalBox {
    height: 47px;
  }
}
