

.country {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    padding: 0 16px 0 14px;
}

.country-emoji {
    font-size: 26px;
    flex-shrink: 0;
    flex-grow: 0;
}

.country-name {
    margin: 0 16px 0 32px;
    flex-grow: 1;
    flex-shrink: 1;
}

.country-phone {
    color: #707579;
    flex-shrink: 0;
    flex-grow: 0;
}
