

@keyframes blink {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.header-progress {
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.header-progress:nth-of-type(3) {
    animation-delay: 0.15s;
}

.header-progress:nth-of-type(4) {
    animation-delay: 0.3s;
}
