.authorization-form {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  margin-top: 100px;
  margin-bottom: 100px;
}

.authorization-form-content {
  margin: 0 auto 0;
  max-width: 664px;
  background-color: #080808;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.authorization-form-content .footer-wrapper {
  margin-top: auto;
}
.authorization-header {
  font-size: 22px;
  font-family: 'Montserrat';
  padding: 22px 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.authorization-header-content {
  word-wrap: break-word;
  white-space: nowrap;
}

.authorization-actions {
  text-align: center;
  display: flex;
}

.btn-box-shadow {
  box-shadow: 0 0 8px 1px #ffb84d !important;
}
.custom-error-text > p:last-child {
  position: relative;
  margin-left: 0;
  padding-left: 0;
  font-size: 0.715rem;
}
