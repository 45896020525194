.telegram-app *:focus {
  outline: none;
}

html {
  background: #fff;
  height: 100%;
  width: 100%;
}

body {
  /* margin: 0; */
  /* padding: 0; */
  /* height: 100%; */
  /* width: 100%; */
  /* font: 16px/21px 'Roboto', sans-serif; */
  /* overflow: hidden; */
  /* background: var(--background); */
  /* color: var(--text-primary); */

  /* scrollbar-color: rgba(0, 0, 0, 0.25) transparent; */
  /* scrollbar-width: thin; */
}

.telegram-app ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

.telegram-app ::-webkit-scrollbar-track {
  background: transparent;
}

.telegram-app ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.telegram-app a {
  color: var(--color-accent-main);
}

.telegram-app code {
  color: var(--color-accent-dark);
}

.telegram-app pre {
  border-color: var(--border);
  color: var(--color-accent-dark);
}

.telegram-app pre::selection {
  color: var(--text-primary);
  background-color: highlight;
}

.telegram-app b,
.telegram-app strong {
  font-weight: 700;
}

.telegram-app mark {
  background-color: #fff8cc;
  border-radius: 3px;
  padding: 0 3px;
}

#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: transparent;
}

.telegram-app .page {
  height: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1680px;
  /*min-width: 300px;*/
  margin: 0 auto;
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: var(--border);
  background: var(--background);
  color: var(--text-primary);
  overflow: hidden;
}

.telegram-app .page-third-column {
  max-width: 1680px;
}

.telegram-app .pointer {
  cursor: pointer;
}

.telegram-app .notification-close-button {
  padding: 4px;
}

.telegram-app .scrollbars-hidden {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.telegram-app .scrollbars-hidden::-webkit-scrollbar {
  display: none;
}

.telegram-logo {
  position: relative;
}

.telegram-logo > svg {
  width: 83px;
  height: 83px;
  fill: #50a2e9;
  border-radius: 83px;
  box-shadow: 0 0 8px #50a2e9;
}
.telegram-logo::before {
  content: ' ';
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border-radius: 83px;
  background-color: white;
}
