

.confirm-code-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.code-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
