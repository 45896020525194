

.auth-caption {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.reward-caption-text {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.auth-caption-telegram-logo {
    width: 160px;
    height: 160px;
    margin: 107px auto 44px;
    fill: #50A2E9;
    background-color: white;
    border-radius: 500px;
}
