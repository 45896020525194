.auth-root {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    flex-shrink: 0;
}

.auth-title {
    font-size: 32px;
    font-weight: 500;
    margin: 0 auto 7px auto;
}

.auth-title-typography {
    font-size: 32px;
    font-weight: 500;
}

.auth-subtitle {
    color: var(--text-secondary);
    margin: 0 auto 14px auto;
    text-align: center;
}

.auth-button {
    margin: 12px 0;
    padding: 15px 16px;
    width: 200px;
    border-radius: 0;
}
.auth-button-full {
    margin: 12px 0;
    padding: 15px 16px;
    width: 100%;
    border-radius: 0;
}

.auth-input {
    margin: 12px 0;
}

.auth-input>label {
    transform: translate(0px, -6px) scale(1) !important;
    font-weight: bold;
    color: white;
}

.auth-input>div {
    margin-top: 20px;
    height: 55px;
    border-radius: 0px;
}

.auth-input>div legend {
    max-width: 0 !important;
}

.sign-in-wrapper {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
}

.sign-in-actions {
    display: flex;
    flex-direction: column;
}

.sign-in-keep {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 360px;
    height: 56px;
    margin: -4px 12px 4px;
    padding: 0 7px;
}

.sign-in-logo {
    width: 160px;
    height: 160px;
    margin: 107px auto 44px;
}

.sign-in-continue-on {
    text-align: center;
    cursor: pointer;
    height: 54px;
    margin: 12px 0;
}